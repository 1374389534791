<script lang="ts">
	import { type ISbRichtext, storyblokEditable } from '@storyblok/svelte';
	import RichText from './RichText.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';

	interface RichTextBlok {
		text: ISbRichtext;
	}

	export let blok: RichTextBlok;
	export let additionalClass: string = '';
	export let boxWrapper: boolean = true;
</script>

<div
	use:storyblokEditable={blok}
	class={classes(additionalClass, boxWrapper ? 'box-x-wrapper' : '')}
>
	<RichText text={blok.text} class="richTextBlock" />
</div>
