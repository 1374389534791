import { PUBLIC_SEARCH_API_KEY, PUBLIC_SEARCH_API_URL } from '$env/static/public';
import type { SupportedLanguages } from '$lib/i18n/constants';

type SearchOptions = {
	filter?: string;
	offset?: number;
	limit?: number;
	facets?: string[];
	attributesToRetrieve?: string[];
	showMatchesPosition?: boolean;
	page?: number;
	hitsPerPage?: number;
	sort?: string[];
};

type SearchClientOptions = {
	searchUrl?: string;
	apiKey?: string;
	locale?: SupportedLanguages;
	fetchFn?: typeof fetch;
};

export type FacetValue = Record<string, number>;

export type Facets = Record<string, FacetValue>;
export type StatFacets = Record<
	string,
	{
		min: number;
		max: number;
	}
>;

interface SingleMatch {
	start: number;
	length: number;
}

interface MatchInfo {
	[key: string]: SingleMatch[];
}

export interface WithMatchesInfo {
	_matchesPosition?: MatchInfo;
}

export interface SearchResponse<E> {
	hits: (E & WithMatchesInfo)[];
	offset: number;
	limit: number;
	totalHits: number;
	estimatedTotalHits: number;
	totalPages: number;
	page: number;
	exhaustiveNbHits: boolean;
	facetDistribution: Facets;
	facetStats: StatFacets;
	exhaustiveFacetsCount: boolean;
	processingTimeMs: number;
	query: string;
}

export class SearchClient {
	private searchUrl: string;
	private readonly apiKey: string;

	constructor(private readonly options?: SearchClientOptions) {
		const { searchUrl, apiKey, locale: language } = options || {};
		const originalUrl = searchUrl || PUBLIC_SEARCH_API_URL;
		this.searchUrl = originalUrl?.endsWith('/search')
			? originalUrl.replace('/search', `${language !== 'de' ? `-${language}` : ''}/search`)
			: `${originalUrl}${language !== 'de' ? `-${language}` : ''}/search`;

		this.apiKey = apiKey || PUBLIC_SEARCH_API_KEY;
	}

	public changeLocale(locale: SupportedLanguages): void {
		locale = locale.toLowerCase();
		const originalUrl = this.options?.searchUrl || PUBLIC_SEARCH_API_URL!;
		this.searchUrl = originalUrl.endsWith('/search')
			? originalUrl.replace('/search', `${locale !== 'de' ? `-${locale}` : ''}/search`)
			: `${originalUrl}${locale !== 'de' ? `-${locale}` : ''}/search`;
	}

	private getUrl() {
		const locale = this.options?.locale ?? 'de'; // ;
		const originalUrl = this.options?.searchUrl || PUBLIC_SEARCH_API_URL!;
		return originalUrl.endsWith('/search')
			? originalUrl.replace('/search', `${locale !== 'de' ? `-${locale}` : ''}/search`)
			: `${originalUrl}${locale !== 'de' ? `-${locale}` : ''}/search`;
	}

	public async search<E>(q: string, opts?: SearchOptions): Promise<SearchResponse<E>> {
		const fetchFn = this.options?.fetchFn || fetch;
		const response = await fetchFn(this.getUrl(), {
			body: JSON.stringify({
				q,
				...opts
			}),
			headers: {
				Authorization: `Bearer ${this.apiKey}`,
				'Content-Type': 'application/json'
			},
			method: 'POST'
		});
		if (response.status !== 200) {
			throw new Error(
				`Search API returned ${response.status} ("${await response.text()}") - ${JSON.stringify({
					q,
					...opts
				})}`
			);
		}
		return response.json();
	}
}

export const ALL_FACETS = ['*'];

export const DEFAULT_SEARCH_CLIENT = new SearchClient({
	locale: 'de',
	searchUrl: PUBLIC_SEARCH_API_URL,
	apiKey: PUBLIC_SEARCH_API_KEY
});

export const serverSideSearch = (fetchFn: typeof fetch, locale: string) =>
	new SearchClient({
		locale,
		searchUrl: PUBLIC_SEARCH_API_URL,
		apiKey: PUBLIC_SEARCH_API_KEY,
		fetchFn
	});

export const clientSideSearch = (locale: string) =>
	new SearchClient({
		locale,
		searchUrl: PUBLIC_SEARCH_API_URL,
		apiKey: PUBLIC_SEARCH_API_KEY
	});
