import type { CountryCode$options } from '$houdini';
import { PUBLIC_SHOPIFY_LOCALES, PUBLIC_SHOPIFY_MARKETS } from '$env/static/public';
import { currencyCodeToSymbol } from '$lib/utils/i18n';

export type LocaleResponse = Array<{
	/** Currency settings for the market */
	currencySettings: {
		baseCurrency: {
			/** The currency code of the market */
			currencyCode: string;
			/** The name of the currency */
			currencyName: string;
			/** If  */
			enabled: boolean;
		};
		/** Should always be false since we do not allow currencies to be automatically converted */
		localCurrencies: boolean;
	};
	/** Flag if market is available in sales channel */
	enabled: boolean;
	/** Name of the market */
	name: string;
	/** If market is set as primary market in spotify */
	primary: boolean;
	/** Shopify market id */
	id: `gid://shopify/Market/${string}`;
	/** Regions that are associated with this market. We try to have a 1-to-1 mapping of market to region. */
	regions: {
		nodes: [
			{
				/** Region name */
				name: CountryCode$options;
				/** Shopify region id */
				id: `gid://shopify/MarketRegionCountry/?${string}`;
				/** Country code */
				code: string;
			}
		];
	};
}>;

export const shippingMarkets = JSON.parse(PUBLIC_SHOPIFY_MARKETS ?? '{}') as LocaleResponse;
export const shippingCountries = PUBLIC_SHOPIFY_LOCALES?.split(',') ?? [];
